@import "../variables";

.card.widget {
  margin-bottom: 1.5rem;
}

/*
Dark theme for widgets
 */
body.dark .card {
  background-color: $dark-app-background-color-secondary;
}
body.dark .card .card-header {
  background-color: rgba(255, 255, 255, 0.03);
}
