@use "sass:math";


// ----------------------------------------
// Table of Contents
// ----------------------------------------
// - colors
// - helpers


// ----------------------------------------
// -- colors
// ----------------------------------------
// light
$light-color: #fff !default;
$light-opposite-color: #3d464d !default;
$light-opposite-muted-color: #999 !default;
$light-opposite-alt-color: #6c757d !default;
$light-divider-color: #ebebeb !default;

// dark
$dark-color: #3d464d !default;
$dark-opposite-color: $light-color !default;
// accent
$accent-color: #ffd333 !default;
$accent-opposite-color: #3d464d !default;
//links
$link-color: #1a66ff !default;
$link-hover-color: #1a66ff !default;

//border
$body-divider-color-dark: #2b2b2c !default;
$body-divider-color: #ebebeb !default;


//  /* Light theme styles go here */
$app-background-color: #fff;
$app-text-color: #222;
$app-link-color: #0033cc;

/* Dark theme styles go here */
$dark-app-background-color: #121212;
$dark-app-background-color-secondary: #212529;
$dark-app-text-color: #e9ecef;
$dark-app-link-color: #a1a137;
