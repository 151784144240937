/* timeline */

.timeline {
  position: relative;
  background: inherit;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5rem;
  margin: 0 auto 1rem auto;
  overflow: hidden;
}
.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  /*border-right: 2px dashed #4b546f;*/
  border-right: 2px dashed #a1a1a1;
  height: 100%;
  display: block;
}

.timeline-row {
  padding-left: 50%;
  position: relative;
  margin-bottom: 90px;
}
.timeline-row .timeline-time {
  position: absolute;
  right: 50%;
  top: 15px;
  text-align: right;
  margin-right: 20px;
  /*color: #bcd0f7;*/
  color: inherit;
  font-size: 1.5rem;

}
.timeline-row .timeline-time small {
  display: block;
  font-size: 0.8rem;
}

body.dark .timeline-row .timeline-content {
  background-color: #212529;
}

.timeline-row .timeline-content {
  position: relative;
  padding: 20px 30px;
  /*background: #1a233a;*/
  background-color: rgba(247,247,247,0.82);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

body.dark .timeline-row .timeline-content:after {
  background-color: #212529;
}

.timeline-row .timeline-content:after {
  content: "";
  position: absolute;
  top: 20px;
  height: 16px;
  width: 16px;
  /*background: #1a233a;*/
  background-color: rgba(247,247,247,0.82);

}

body.dark .timeline-row .timeline-content:before {
  background-color: #121212;
}

.timeline-row .timeline-content:before {
  content: "";
  position: absolute;
  top: 20px;
  right: -49px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  z-index: 10;
  background: #fff;
  /*background: #272e48;*/
  /*border: 2px dashed #4b546f;*/
  border: 2px dashed #a1a1a1;
}
.timeline-row .timeline-content h4 {
  margin: 0 0 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 150%;
}
.timeline-row .timeline-content p {
  margin-bottom: 30px;
  line-height: 150%;
}
.timeline-row .timeline-content i {
  font-size: 2rem;
  line-height: 100%;
  padding: 15px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  //background: #6c757d;
  /*background: #272e48;*/
  margin-bottom: 10px;
  display: inline-block;
}
.timeline-row .timeline-content .thumbs {
  margin-bottom: 20px;
  display: flex;
}
.timeline-row .timeline-content .thumbs img {
  margin: 5px;
  //max-width: 60px;
  max-height: 100px;
}

.timeline-content .gold-shadow {
  text-shadow: #e7d37c 3px 3px 3px;
}

.timeline-row .timeline-content .badge {
  color: #ffffff;
  background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
}
.timeline-row:nth-child(even) .timeline-content {
  margin-left: 40px;
  text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:after {
  left: -8px;
  right: initial;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-135deg);
}
.timeline-row:nth-child(even) .timeline-content:before {
  left: -52px;
  right: initial;
}
.timeline-row:nth-child(odd) {
  padding-left: 0;
  padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
  right: auto;
  left: 50%;
  text-align: left;
  margin-right: 0;
  margin-left: 20px;
}
.timeline-row:nth-child(odd) .timeline-content {
  margin-right: 40px;
}
.timeline-row:nth-child(odd) .timeline-content:after {
  right: -8px;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

@media (max-width: 992px) {
  .timeline {
    padding: 15px;
  }
  .timeline:after {
    border: 0;
  }
  .timeline .timeline-row:nth-child(odd) {
    padding: 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content {
    margin: 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content:before {
    display: none;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content:after {
    display: none;
  }
  .timeline .timeline-row:nth-child(even) {
    padding: 0;
  }
  .timeline .timeline-row:nth-child(even) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    text-align: left;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content {
    margin: 0;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content:before {
    display: none;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content:after {
    display: none;
  }
}