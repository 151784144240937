
.history-list dd {
  margin-bottom: 2rem;
}
.history-list dt {
  margin-bottom: 1rem;
}

.history-list blockquote {
  font-size: inherit;
  font-style: italic;
}

