@import 'variables';


:root {
  --bs-body-font-family: 'Source Code Pro', monospace;
}

html {
  font-size: 14px;
}

body {
  color: $app-text-color;
  background: $app-background-color;
}

a {
  text-decoration: none;
}

a, .navbar-light .navbar-text a {
  color: $app-link-color;
}

.nav-link {
  text-transform: uppercase;
}

.sc-logo {
  display: block;
  width: 280px;
  height: 62px;
  background-image: url("/public/images/header-sc-both.png");
  background-size: 100%;
  background-position-y: 100%;
}

.cursor-pointer:hover, .clickable {
  cursor: pointer;
}


/*swup*/
.transition-fade {
  transition: 0.2ms;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}
