@import 'variables';

body.dark {
  color: $dark-app-text-color;
  background: $dark-app-background-color;
  font-weight: 300;
}

/* FORMS */
body.dark .form-floating label {
  color: $app-text-color;
  font-weight: 500;
}

body.dark .form-text {
  color:$dark-app-text-color;
}


body.dark .dropdown-item, body.dark .btn-link {
  font-weight: 300;
}

body.dark .alert {
  font-weight: 500;
}

body.dark a, body.dark .navbar-text a {
  color: $dark-app-link-color;
}

body.dark img {
  filter: brightness(.8) contrast(1.2);
}

body.dark .sc-logo {
  background-position-y: 0%;
}

.accordion-body, .accordion-item {
  background: inherit;
}
