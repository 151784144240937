/*
// .comments-list
*/
@import '../variables';


.comments-list {
  list-style: none;
  margin: 0;
  //padding: 0;
}

.comments-list__item {
  & + & {
    border-top: 1px solid $body-divider-color;
    margin-top: 24px;
    padding-top: 28px;
  }
}


body.dark .comments-list__item {

  border-top-color: $body-divider-color-dark;

}


.comments-list--level--0 {
  & > .comments-list__item:last-child {
    padding-bottom: 28px;
    border-bottom: 1px solid $body-divider-color;
  }
}

.comments-list--level--1 {
  border-top: 1px solid $body-divider-color;
  margin-top: 24px;
  padding-top: 28px;

  & > .comments-list__item {

    margin-left: 46px;

  }
}


@media (max-width: 767px) {
  .comments-list__item + .comments-list__item {
    margin-top: 20px;
    padding-top: 24px;
  }
  .comments-list--level--1 {
    margin-top: 20px;
    padding-top: 24px;
  }
}


.comment {
  //display: flex;
  clear: both;
}

.comment__avatar {
  max-width: 70px;
  //flex-shrink: 0;
  float: left;

  margin-left: 20px;
  margin-right: 24px;

  img {
    max-width: 100%;
    border-radius: 222px;
  }
}

.comment__content {
  flex-grow: 1;
}

.comment__header {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment__author {
  font-size: 16px;
  font-weight: 500;

  a {
    color: inherit;
    transition: color .2s;
  }

  a:hover {
    color: $link-hover-color;
  }
}

.comment__reply .btn {
  height: 22px;
  padding: .125rem .375rem;
  display: block;
}

.comment__text {
  font-size: 16px;
  margin-top: 6px;
}

.comment__date {
  margin-top: 10px;
  color: #888888;
  font-size: 12px;
  text-align: right;
}


@media (max-width: 767px) {
  .comment__avatar {
    margin-left: 0;
  }
}

//@media (max-width: breakpoint(xs-end)) and (max-width: breakpoint(xs-end)) {
//  .comment__avatar {
//    display: none;
//  }
//}

